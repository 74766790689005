import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "messageBox"
};
const _hoisted_2 = {
  style: {
    "font-size": "20px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.src, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "Allmiddle box",
      key: index
    }, [_createElementVNode("div", _hoisted_2, _toDisplayString(item.num), 1), _createElementVNode("div", null, _toDisplayString(item.name), 1), _createElementVNode("div", null, _toDisplayString(item.en_name), 1)]);
  }), 128))]);
}