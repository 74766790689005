import * as api from '@/api/indexMini';
import { defineComponent } from 'vue';
export default defineComponent({
  data() {
    return {
      src: []
    };
  },
  async created() {
    try {
      const res = await api.getStatisticsData({
        data_type: 'PID'
      });
      this.src = res.data;
    } catch (e) {
      console.error(e);
    }
  }
});